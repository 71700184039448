import React, { useState } from "react";
import { Container, Form, Button, Alert, Image, Card, Row, Col } from "react-bootstrap";
import "../style/Recharge.css";

export default function Recharge() {
  const [amount, setAmount] = useState("");
  const [checkoutUrl, setCheckoutUrl] = useState(null);
  const [bankInfo, setBankInfo] = useState(null);
  const [error, setError] = useState(null);
  const [selectedMethod, setSelectedMethod] = useState(null);
  

  const handleSubmit = async (e, method) => {
    e.preventDefault();
    setError(null);
    setCheckoutUrl(null);
    setBankInfo(null);
    setSelectedMethod(method);

    if (method === "card") {
      setError("Chức năng nạp bằng thẻ cào đang phát triển!");
      return;
    }

    if (!amount || amount <= 0) {
      setError("Vui lòng nhập số tiền hợp lệ!");
      return;
    }

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        setError("Vui lòng đăng nhập để nạp point!");
        return;
      }

      let url, response;
      if (method === "qr") {
        console.log(`[${new Date().toISOString()}] Gửi yêu cầu tạo giao dịch PayOS, amount: ${amount}`);
        url = "https://savinashop.com/api/users/recharge/create";
        response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ amount: Number(amount) }),
        });
      } else if (method === "bank") {
        console.log(`[${new Date().toISOString()}] Gửi yêu cầu tạo giao dịch CK Ngân Hàng, amount: ${amount}`);
        url = "https://savinashop.com/api/users/recharge/bank-transfer";
        response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ amount: Number(amount) }),
        });
      }

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Lỗi tạo giao dịch");
      }

      if (method === "qr") {
        console.log(`[${new Date().toISOString()}] Nhận checkoutUrl: ${data.checkoutUrl}`);
        setCheckoutUrl(data.checkoutUrl);
      } else if (method === "bank") {
        console.log(`[${new Date().toISOString()}] Nhận thông tin ngân hàng:`, data.bankInfo);
        setBankInfo(data.bankInfo);
      }
    } catch (err) {
      console.error(`[${new Date().toISOString()}] Lỗi nạp point: ${err.message}`);
      setError(err.message);
    }
  };

  // Tạo link VietQR cho chuyển khoản ngân hàng
  const generateVietQRLink = (bankInfo, amount) => {
    const bankId = "970422"; // MB Bank
    const accountNo = "150420044444"; // Số tài khoản
    const template = "compact"; // Template VietQR
    const accountName = "SAVINA SHOP"; // Tên tài khoản
    const description = encodeURIComponent(bankInfo.content);
    return `https://img.vietqr.io/image/${bankId}-${accountNo}-${template}.png?amount=${amount}&addInfo=${description}&accountName=${accountName}`;
  };

  return (
    <Container fluid className="pt-5 mt-5" style={{ minHeight: "100vh", background: "#f8f9fa" }}>
      <Row className="justify-content-center">
        <Col xs={12} sm={10} md={8} lg={6}>
          <Card
            className="shadow-lg border-0"
            style={{
              borderRadius: "20px",
              background: "linear-gradient(135deg, #fff, #f8f9fa)",
              overflow: "hidden",
            }}
          >
            <Card.Header
              className="text-center py-4"
              style={{
                background: "linear-gradient(90deg, #ff6b6b, #ff4d4d)",
                borderBottom: "none",
              }}
            >
              <h2
                className="mb-0"
                style={{ color: "#fff", fontWeight: "700", fontFamily: "'Poppins', sans-serif" }}
              >
                Nạp Point Savina
              </h2>
            </Card.Header>
            <Card.Body className="p-4">
              <Form>
                <Form.Group className="mb-4">
                  <Form.Label
                    className="fw-bold"
                    style={{ color: "#2d3436", fontFamily: "'Poppins', sans-serif" }}
                  >
                    Số tiền (VND)
                  </Form.Label>
                  <Form.Control
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    placeholder="Nhập số tiền (tối thiểu 10,000 VND)"
                    min="10000"
                    className="rounded-pill"
                    style={{
                      padding: "12px",
                      border: "1px solid #ff6b6b",
                      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  />
                </Form.Group>
                <Row className="justify-content-center mb-4">
                  <Col xs={12} className="text-center">
                    <Form.Label
                      className="fw-bold"
                      style={{ color: "#2d3436", fontFamily: "'Poppins', sans-serif", fontSize: "1.1rem" }}
                    >
                      Vui lòng chọn phương thức thanh toán phía dưới!
                    </Form.Label>
                  </Col>
                </Row>
                <Row className="g-3 mb-4">
                  <Col xs={12} sm={4}>
                    <Button
                      onClick={(e) => handleSubmit(e, "qr")}
                      className="w-100 rounded-pill py-2"
                      style={{
                        background: "linear-gradient(90deg, #ff6b6b, #ff4d4d)",
                        border: "none",
                        fontWeight: "600",
                        fontFamily: "'Poppins', sans-serif",
                        transition: "all 0.3s ease",
                      }}
                    >
                      <i className="bi bi-qr-code me-2"></i> Quét mã QR
                    </Button>
                  </Col>
                  <Col xs={12} sm={4}>
                    <Button
                      onClick={(e) => handleSubmit(e, "bank")}
                      className="w-100 rounded-pill py-2"
                      style={{
                        background: "linear-gradient(90deg, #28a745, #218838)",
                        border: "none",
                        fontWeight: "600",
                        fontFamily: "'Poppins', sans-serif",
                        transition: "all 0.3s ease",
                      }}
                    >
                      <i className="bi bi-bank me-2"></i> CK Ngân Hàng
                    </Button>
                  </Col>
                  <Col xs={12} sm={4}>
                    <Button
                      onClick={(e) => handleSubmit(e, "card")}
                      className="w-100 rounded-pill py-2"
                      style={{
                        background: "#6c757d",
                        border: "none",
                        fontWeight: "600",
                        fontFamily: "'Poppins', sans-serif",
                        opacity: "0.7",
                        cursor: "not-allowed",
                        transition: "all 0.3s ease",
                      }}
                      disabled
                    >
                      <i className="bi bi-credit-card me-2"></i> Thẻ cào
                    </Button>
                  </Col>
                </Row>
              </Form>

              {error && (
                <Alert
                  variant="danger"
                  className="mt-3 rounded-pill text-center"
                  style={{
                    background: "#dc3545",
                    color: "#fff",
                    fontFamily: "'Poppins', sans-serif",
                    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  {error}
                </Alert>
              )}

              {checkoutUrl && selectedMethod === "qr" && (
                <div className="text-center mt-4">
                  <p
                    className="fw-bold"
                    style={{ color: "#2d3436", fontFamily: "'Poppins', sans-serif" }}
                  >
                    Quét mã QR để nạp point:
                  </p>
                  <Image
                    src={`https://api.qrserver.com/v1/create-qr-code/?size=250x250&data=${encodeURIComponent(checkoutUrl)}`}
                    alt="QR Code Thanh Toán"
                    className="mx-auto d-block shadow-sm"
                    style={{
                      borderRadius: "15px",
                      border: "3px solid #ff6b6b",
                      padding: "10px",
                      background: "#fff",
                      transition: "transform 0.3s ease",
                      maxWidth: "100%",
                      height: "auto",
                    }}
                    onMouseEnter={(e) => (e.target.style.transform = "scale(1.05)")}
                    onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
                  />
                  <p className="mt-3" style={{ fontFamily: "'Poppins', sans-serif" }}>
                    Hoặc{" "}
                    <a
                      href={checkoutUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: "#ff6b6b",
                        fontWeight: "600",
                        textDecoration: "none",
                        transition: "color 0.3s ease",
                      }}
                      onMouseEnter={(e) => (e.target.style.color = "#ff4d4d")}
                      onMouseLeave={(e) => (e.target.style.color = "#ff6b6b")}
                    >
                      nhấn vào đây
                    </a>{" "}
                    để thanh toán.
                  </p>
                </div>
              )}

              {bankInfo && selectedMethod === "bank" && (
                <div className="text-center mt-4">
                  <p
                    className="fw-bold"
                    style={{ color: "#2d3436", fontFamily: "'Poppins', sans-serif" }}
                  >
                    Quét mã QR để chuyển khoản:
                  </p>
                  <Image
                    src={generateVietQRLink(bankInfo, amount)}
                    alt="QR Code Chuyển Khoản"
                    className="mx-auto d-block shadow-sm"
                    style={{
                      borderRadius: "10px",
                      border: "2px solid #28a745",
                      padding: "5px",
                      background: "#fff",
                      transition: "transform 0.3s ease",
                      maxWidth: "200px", // Thu nhỏ mã QR
                      height: "auto",
                    }}
                    onMouseEnter={(e) => (e.target.style.transform = "scale(1.05)")}
                    onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
                  />
                  <div
                    className="p-3 rounded mt-3"
                    style={{
                      background: "#e9ecef",
                      border: "1px solid #28a745",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  >
                    <p className="mb-1"><strong>Ngân hàng:</strong> {bankInfo.bankName}</p>
                    <p className="mb-1"><strong>Số tài khoản:</strong> {bankInfo.accountNumber}</p>
                    <p className="mb-1"><strong>Nội dung:</strong> {bankInfo.content}</p>
                    <p className="mb-0"><strong>Số tiền:</strong> {amount} VND</p>
                  </div>
                  <p className="mt-3" style={{ fontFamily: "'Poppins', sans-serif", color: "#dc3545" }}>
                    Lưu ý: Vui lòng chuyển khoản đúng nội dung để hệ thống tự động xử lý!
                  </p>
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
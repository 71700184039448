import React, { useState, useEffect } from "react";
import { Container, Table, Alert } from "react-bootstrap";
import "../style/RechargeHistory.css";

export default function RechargeHistory() {
  const [history, setHistory] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          setError("Vui lòng đăng nhập để xem lịch sử nạp point!");
          setLoading(false);
          return;
        }

        console.log(`[${new Date().toISOString()}] Bắt đầu lấy lịch sử nạp point từ API`);
        const response = await fetch("https://savinashop.com/api/users/recharge/history", { // Giữ nguyên endpoint như trong file gốc
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Lỗi khi lấy lịch sử nạp point");
        }

        const data = await response.json();
        console.log(`[${new Date().toISOString()}] Nhận dữ liệu lịch sử:`, data);
        setHistory(data);
      } catch (err) {
        console.error(`[${new Date().toISOString()}] Lỗi: ${err.message}`);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchHistory();
  }, []);

  return (
    <Container className="my-5">
      <h2 className="text-center mb-4">Lịch sử nạp point</h2>

      {loading && <p className="text-center">Đang tải...</p>}
      {error && (
        <Alert variant="danger" className="mx-auto" style={{ maxWidth: "600px" }}>
          {error}
        </Alert>
      )}

      {!loading && !error && history.length === 0 && (
        <p className="text-center">Bạn chưa có giao dịch nạp point nào.</p>
      )}

      {!loading && !error && history.length > 0 && (
        <Table striped bordered hover responsive className="mt-3">
          <thead>
            <tr>
              <th>Mã giao dịch</th>
              <th>Số tiền (VND)</th>
              <th>Mô tả</th>
              <th>Trạng thái</th>
              <th>Thời gian</th>
            </tr>
          </thead>
          <tbody>
            {history.map((item) => (
              <tr key={item.transactionId}>
                <td>{item.transactionId}</td>
                <td>{item.amount.toLocaleString()}</td>
                <td>{item.description}</td>
                <td>{item.status}</td>
                <td>{new Date(item.createdAt).toLocaleString("vi-VN")}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Container>
  );
}
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import "../style/OrderHistory.css";
import { Alert, Box, Typography, Card, CardContent, CardMedia } from "@mui/material";

export default function OrderHistory({ user }) {
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      console.log(`[${new Date().toISOString()}] Người dùng chưa đăng nhập, không lấy lịch sử mua hàng`);
      navigate("/");
      return;
    }

    const fetchOrders = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.log(`[${new Date().toISOString()}] Không tìm thấy token trong localStorage, chuyển hướng đến /`);
        navigate("/");
        return;
      }

      console.log(`[${new Date().toISOString()}] Bắt đầu lấy lịch sử mua hàng từ API`);
      try {
        const response = await fetch("https://savinashop.com/api/orders/user/orders", {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (!response.ok) {
          const errorData = await response.json();
          if (response.status === 401 || response.status === 403) {
            console.log(`[${new Date().toISOString()}] Phiên đăng nhập hết hạn hoặc không có quyền, chuyển hướng đến /`);
            localStorage.removeItem("token");
            throw new Error("Phiên đăng nhập hết hạn hoặc bạn không có quyền truy cập!");
          }
          throw new Error(errorData.message || `Lỗi lấy lịch sử mua hàng: ${response.status}`);
        }

        const data = await response.json();
        console.log(`[${new Date().toISOString()}] Dữ liệu đơn hàng:`, JSON.stringify(data, null, 2));
        setOrders(Array.isArray(data) ? data.sort((a, b) => new Date(b.date) - new Date(a.date)) : []);
      } catch (error) {
        console.error(`[${new Date().toISOString()}] Lỗi khi lấy lịch sử mua hàng: ${error.message}`);
        setError(error.message);
        toast.error(error.message || "Đã có lỗi xảy ra khi lấy lịch sử mua hàng!", {
          duration: 5000,
          position: "bottom-center",
          style: {
            background: "#dc3545",
            color: "#fff",
            fontSize: "1rem",
            fontWeight: 500,
            borderRadius: "8px",
            padding: "12px 20px",
            boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
            minWidth: "300px",
            maxWidth: "500px",
          },
        });
        if (error.message.includes("Phiên đăng nhập hết hạn")) {
          navigate("/");
        }
      }
    };

    fetchOrders();
  }, [user, navigate]);

  if (!user) {
    return (
      <Box sx={{ my: 5, textAlign: "center" }}>
        <Typography variant="body1" color="textSecondary">
          Vui lòng đăng nhập để xem lịch sử mua hàng.
        </Typography>
      </Box>
    );
  }

  return (
    <Box component="main" sx={{ my: 5, mx: "auto", maxWidth: "1200px" }}>
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{ fontWeight: "bold", textTransform: "uppercase" }}
        role="heading"
        aria-level="1"
      >
        Lịch sử mua hàng
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 4 }} role="alert">
          <i className="bi bi-exclamation-triangle me-2"></i>
          {error}
        </Alert>
      )}

      {orders.length === 0 ? (
        <Typography variant="body1" align="center" color="textSecondary">
          Bạn chưa có đơn hàng nào.
        </Typography>
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
          {orders.map((order) => (
            <Card key={order._id} className="order-history-card shadow-sm">
              <CardContent sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                  Đơn hàng ngày {new Date(order.date).toLocaleString()}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Tổng tiền: <strong>{order.total.toLocaleString()} point</strong>
                </Typography>
                {order.items.map((item, index) => (
                  <Box key={index} className="order-item">
                    <CardMedia
                      component="img"
                      image={item.image ? `https://savinashop.com${item.image}` : "https://placehold.co/80x80"}
                      alt={`Hình ảnh sản phẩm ${item.name}`}
                      onError={(e) => (e.target.src = "https://placehold.co/80x80?text=Image+Not+Found")}
                    />
                    <Box className="order-item-details">
                      <Typography variant="body1">
                        {item.name} - {item.quantity} x {item.variant.price.toLocaleString()} point
                      </Typography>
                      {item.note && (
                        <Typography variant="body2" color="textSecondary" component="p">
                          Ghi chú: {item.note}
                        </Typography>
                      )}
                      {item.deliveryInfo && (
                        <Typography variant="body2" color="textSecondary" component="p">
                          Thông tin gửi: {item.deliveryInfo}
                        </Typography>
                      )}
                      {item.rejectionReason && (
                        <Typography variant="body2" color="error" component="p">
                          Lý do từ chối: {item.rejectionReason}
                        </Typography>
                      )}
                      <Typography
                        className={`status-tag status-${
                          item.itemStatus === "Đang xử lý" ? "pending" : item.itemStatus === "Thành công" ? "success" : "failed"
                        }`}
                      >
                        Trạng thái: {item.itemStatus}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </CardContent>
            </Card>
          ))}
        </Box>
      )}
    </Box>
  );
}